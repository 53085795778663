<template>
  <div class="posts">
    <v-container class="main-container d-flex flex-wrap">
      <div class="col-12 col-mm-8 pa-1">
        <TopContent></TopContent>

        <div ref="topdiv" ></div>
<!--
        <div ref="topdiv" class="selections d-flex flex-wrap align-center justify-center justify-lg-space-between my-5">
          <div class="my-3 mx-1 d-flex align-center categorywidth">
        <p class="ma-0 ml-3">دسته</p>
        <v-select
            :items="catsitems"
            filled
            dark
            append-icon=""
            rounded
            hide-details
            dense
        >
        </v-select>
          </div>


          <div class="my-3 mx-1 d-flex align-center categorywidth">
            <p class="ma-0 ml-3" style="min-width: 81px">سال انتشار</p>
            <v-select
                :items="releaseitems"
                filled
                dark
                append-icon=""
                rounded
                hide-details
                dense
            >
            </v-select>
          </div>


          <div class="my-3 mx-1 d-flex align-center categorywidth">
            <p class="ma-0 ml-3" style="min-width: 84px">مرتب سازی</p>
            <v-select
                :items="sortitems"
                filled
                dark
                append-icon=""
                rounded
                hide-details
                dense
            >
            </v-select>
          </div>

          <div class="my-3 mx-1 d-flex align-center categorywidth">
            <p class="ma-0 ml-3" style="min-width: 93px">امتیاز کاربران</p>
            <v-select
                :items="rateitems"
                filled
                dark
                append-icon=""
                rounded
                hide-details
                dense
            >
            </v-select>
          </div>

          <div class="my-3 mx-1 d-flex align-center categorywidth">
            <p class="ma-0 ml-3" >کیفیت</p>
            <v-select
                :items="qualityitems"
                filled
                dark
                append-icon=""
                rounded
                hide-details
                dense
            >
            </v-select>
          </div>

          <div class="my-3 mx-1 d-flex align-center categorywidth">
            <p class="ma-0 ml-3" style="min-width: 62px">رده سنی</p>
            <v-select
                :items="ageitems"
                filled
                dark
                append-icon=""
                rounded
                hide-details
                dense
            >
            </v-select>
          </div>


        </div>
-->

        <p class="my-3" style="font-size: 24px;color: #aeaeae" v-if="$route.query.str" >نتایج جستجو "{{$route.query.str}}" : </p>


        <div :class="{'postloading':pageloading}" class="posts-card d-flex posts-card__wrapresponsive" v-for="post in posts" :key="post.id">
          <div class="posts-card__img">
            <img :src="$store.state.thumbnail + post.image" class="posts-card__img"/>
          </div>
          <div class="mr-3">
            <router-link :to="{name:'single',params:{id:post.id,title:post.title}}" custom v-slot="{ navigate , href }">
            <a @click="navigate" role="link" :href="href" class=" white--text my-2" style="font-size: 22px;cursor: pointer" >{{post.title}}</a>
            </router-link>
            <div class="posts-card__desc">
              <router-link :to="{name:'posts',params:{id:post.catId,title:post.catTitle}}" custom v-slot="{navigate,href}">
            <p class="mb-2">در دسته ی <a :href="href" @click="navigate" role="link" style="color: #568eff">{{ post.catTitle }}</a></p>
              </router-link>
<!--            <p class="mb-2">نویسنده : {{ post.fName }} {{ post.lName }}</p>-->
              <p class="mb-2">تاریخ انتشار : {{ post.createTime | momentDate}}</p>
            <div class="my-16" ></div>
              <p style="text-align: justify;text-justify: inter-word;color: #7a7a7a" ><span style="color: #d1d1d1">توضیحات کوتاه </span>: {{ post.desc }}</p>

            </div>
          </div>
        </div>


        <div class="text-center mx-0 mt-16 mb-10 pagintion">
          <v-pagination
              v-model="page"
              :length="pagecount"
              :total-visible="10"
          ></v-pagination>
        </div>



      </div>
      <div class="col-12 col-mm-4 py-6 px-lg-6 px-2">

        <LiveSearch></LiveSearch>

        <LastPosts></LastPosts>

        <RelatedPosts></RelatedPosts>


      </div>
    </v-container>
  </div>
</template>

<script>
import TopContent from "@/components/TopContent";
import LastPosts from "../components/LastPosts";
import RelatedPosts from "../components/RelatedPosts";
import LiveSearch from "../components/LiveSearch";
import axios from "axios";
import {momentfilter} from "../plugins/moment";

export default {
  name: 'Home',
  mixins : [momentfilter],
  data() {
    return {
      catsitems: ['همه'],
      releaseitems: ['همه'],
      sortitems: ['همه'],
      rateitems: ['همه'],
      qualityitems: ['همه'],
      ageitems: ['همه'],
      page : 1,
      pagecount : 1,
      posts : [],
      title : this.$route.params.title,
      id : this.$route.params.id,
      pageloading:false,
    }
  },
  components: {
    TopContent,
    LastPosts,
    RelatedPosts,
    LiveSearch,
  },
  created() {

    this.fetchPosts();
    if(this.$route.query.page){
      this.page = parseInt(this.$route.query.page);
    }
  },
  metaInfo() {
    return {
      title: this.$route.params.title || this.$route.query.str
      ,link: [{rel: 'canonical', href: this.$store.state.url+'/posts/'+this.id+'/'+this.title}],
      meta: [
         {
          property: 'og:title',
          vmid: 'og:title',
          content: 'مهران مدیا' + '::' + this.$route.params.title
        }
      ],
    }
  },
  methods:{

    fetchPosts(){
      this.pageloading = true;
      let id = null;
      if(this.$route.params.id==0){
        id = null;
      } else {
        id = this.$route.params.id;
      }
      axios.get(this.$store.state.api + 'allpost',{params : {page : this.$route.query.page,catId:id,search:this.$route.query.str}}).then(response => {
        this.items = response.data.data;
        this.posts = this.items.posts;
        this.pageloading = false;
        this.pagecount = Math.ceil(parseInt(this.items.count)/10);
      })
    },
    goto(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;

      window.scrollTo({top: top,
        left: 0,
        behavior: 'smooth'});
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      if(parseInt(this.$route.query.page)){
        this.page = parseInt(this.$route.query.page);
      } else {
        this.page = 1;
      }
      this.goto('topdiv');
      this.fetchPosts();
    },
    page(){

      if(this.page===1){
        this.$router.push({name:'posts',query:{str : this.$route.query.str},params : {title:this.$route.params.title}}).catch(()=>{});
      }

      else if(this.page != parseInt(this.$route.query.page)){
        this.$router.push({name:'posts',query:{page:this.page,str : this.$route.query.str},params : {title:this.$route.params.title}}).catch(()=>{});
      }
      this.fetchPosts();
    }
  }
}
</script>

<style lang="scss">

.theme--light.v-list {
  border-radius: 10px !important;
  overflow: hidden;
}

.theme--light.v-list *{
  /*background-color: #2b2b37 !important;*/
  color: white !important;
  text-align: right !important;
}

.v-sheet.v-list:not(.v-sheet--outlined) {
  background-color: #2b2b37 !important;
  box-shadow: none !important;
}
</style>

<style lang="scss">

.posts .postloading{
  opacity: 0.3;
  filter: blur(1px);
}

.posts .pagintion * {
  color: white !important;
  box-shadow: none !important;
  border-radius: 40% !important;
}

.posts .pagintion .theme--light.v-pagination .v-pagination__item {
  background: #1e1e2d;

}

.posts  .pagintion .theme--light.v-pagination .v-pagination__item--active{
  background-color: #3e3e54 !important;
}

.posts .pagintion .theme--light.v-pagination .v-pagination__navigation {
  background: #1e1e2d !important;
}

.posts .selections *  {
  font-size: 16px !important;
  color: #a3a3a3 !important;
}

.posts .v-select__selections {
  line-height: 22px !important;
}

.posts .v-input * {
  justify-content: center !important;
}

.posts .v-text-field--rounded > .v-input__control > .v-input__slot{
  padding: 0 8px !important;
}

.posts .v-menu__content {
  box-shadow: none !important;
}

.posts .categorywidth {
  width: 300px ;
}

@media only screen and (min-width: 437px) {
  .categorywidth {
    max-width: 203px;
  }
}

@media only screen and (max-width: 500px){
  .v-pagination__navigation{
    margin-right: 2px !important;
    margin-left: 2px !important;
  }
  .pagintion {
    width: 310px;
    margin-right: auto;
    margin-left: auto;
  }
}

</style>

